<template>
	<b-modal id="add-asset-type" size="lg" title="Add Asset Type" ref="modal" ok-title="Add" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Origin:" label-for="Origin"
								description="Only the companies with Asset Owner or Hybrid type are listed here.">
								<b-form-select id="origin" name="Origin" v-model="selCompany" v-validate="'selectRequired'"
									:options="companyOptions" />
								<span v-show="errors.has('Origin')" class="help-block">{{
									errors.first('Origin')
								}}</span>
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Category:" label-for="uom">
								<v-select name="Category" class="style-chooser" label="text" :options="assetCategoryOptions"
									:reduce="(category) => category.value" v-model="form.type" v-validate="{
												required: true,
											}">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a category
										</em>
									</template>
								</v-select>
								<span v-show="errors.has('Category')" class="help-block">{{
									errors.first('Category')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Name" label-for="name"
								:description="'This is a predetermined value based on the selected type and origin'">
								<b-form-input name="Name" type="text" v-model="form.name" v-validate="{
											required: true,
											regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
										}" :readonly="true" maxlength="50" />
								<span v-show="errors.has('Name')" class="help-block">{{
									errors.first('Name')
								}}</span>
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Description" label-for="description" description>
								<b-form-textarea name="Description" type="text" v-model="form.description" maxlength="200"
									:rows="3" placeholder="Description" 
									v-validate="{
										required:true,
										regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
									}"/>
								<span v-show="errors.has('Description')" class="help-block">{{
									errors.first('Description')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Unit of Measure (UOM):" label-for="uom">
								<v-select name="Unit of Measure" class="style-chooser" label="text" :options="uomOptions"
									:reduce="(uom) => uom.value" v-model="form.uom" v-validate="{
												required: true,
											}">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a uom
										</em>
									</template>
								</v-select>
								<span v-show="errors.has('Unit of Measure')" class="help-block">
									{{ errors.first('Unit of Measure') }}
								</span>
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Status">
								<b-form-radio-group v-model="form.isActive" :options="statusOptions" name="isActive" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API & Database
import api from '@/api/assetTypeApi';
import AssetTypesDAO from '@/database/assetTypes';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-asset-type',
	components: {
		Loading,
	},
	props: {
		companyFilter: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			// Asset Type
			form: {
				name: '',
				origin: '',
				originId: '',
				brand: '',
				type: null,
				description: '',
				uom: '',
				isActive: 'true',
			},

			assetCategoryOptions: [],
			uomOptions: [],
			selCompany: config.companyDefaultValue,
			loggedUser: this.$store.getters.loggedUser,

			statusOptions: config.statusOptions,

			allCompaniesObj: {},
			companyOptions: [],

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.updateAssetTypeName();
			}
		},
		'form.type': function () {
			if (this.form.type) {
				this.updateAssetTypeName();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
	},
	mounted() {
		setTimeout(() => {
			this.allCompaniesObj = this.$store.getters.companies;
			this.companyOptions = DropDownItemsUtil.retrieveCompanyAssetOwnerItems(
				this.allCompaniesObj
			);

			let assetTypeCategories = this.$store.getters.assetTypeCategories;
			this.assetCategoryOptions =
				DropDownItemsUtil.retrieveAssetCategoryItems(assetTypeCategories);

			let uoms = this.$store.getters.uoms;
			this.uomOptions = DropDownItemsUtil.retriveUOMItems(uoms);
		}, config.timeout);
	},
	methods: {
		async hasExistingAssetType() {
			let name = this.form.name.trim();
			let assetTypeObj = await AssetTypesDAO.getAssetTypeByName(name);
			return !_.isEmpty(assetTypeObj);
		},

		updateAssetTypeName() {
			let brand = '';
			if (this.selCompany.brand) {
				brand = this.selCompany.brand;
			}
			let type = '';
			if (this.form.type) {
				type = this.form.type;
			}
			this.form.name = brand + ' ' + type;
		},
		updateCompanySelection() {
			this.form.origin = this.selCompany.name;
			this.form.originId = this.selCompany.id;
			this.form.brand = this.selCompany.brand;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// assign the selected company to the user
			this.updateCompanySelection();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			let existsAssetType = await this.hasExistingAssetType();
			if (existsAssetType) {
				this.$toaster.warning('Asset type already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},

		processFormFields() {
			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
		},
		getParam() {
			this.processFormFields();

			return {
				currUserId: this.loggedUser.id,
				assetType: this.form,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await api.saveAssetType(this.getParam());

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(
						`Asset Type "${this.name}" was created successfully.`
					);
					EventBus.$emit('onCloseAddAssetType', {
						assetType: data.assetType,
					});
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating asset type "${this.name}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error creating asset type "${this.name}". Please try again.`
				);
			}
		},

		onReset() {
			/* Reset our form values */
			this.form.name = '';
			this.form.origin = null;
			this.form.originId = null;
			this.form.brand = '';
			this.form.type = null;
			this.form.description = '';
			this.form.uom = null;
			this.form.isActive = 'true';

			// init company
			if (this.companyFilter.id) {
				this.selCompany = this.companyFilter;
			} else {
				this.selCompany = config.companyDefaultValue;
			}

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped>
.lbl-passed {
	font-weight: bold !important;
	color: #28a745 !important;
}

.lbl-failed {
	font-weight: bold !important;
	color: #dc3545 !important;
}
</style>