import { render, staticRenderFns } from "./EditAssetType.vue?vue&type=template&id=93c8693a&scoped=true&"
import script from "./EditAssetType.vue?vue&type=script&lang=js&"
export * from "./EditAssetType.vue?vue&type=script&lang=js&"
import style0 from "./EditAssetType.vue?vue&type=style&index=0&id=93c8693a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93c8693a",
  null
  
)

export default component.exports