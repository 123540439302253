<template>
	<b-modal id="edit-asset-type" size="lg" title="Edit Asset Type" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Origin" label-for="origin"
								description="Only the companies with Asset Owner or Hybrid type are listed here.">
								<b-form-input name="Origin" type="text" v-model="form.origin" maxlength="25" readonly />
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Category" label-for="Category">
								<b-form-input name="Category" type="text" v-model="form.type" maxlength="25" readonly />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Name" label-for="name"
								:description="'This is a predetermined value based on the selected type and origin'">
								<b-form-input name="Name" type="text" v-model="form.name" v-validate="{
										required: true,
										regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
									}" :readonly="true" maxlength="50" />
								<span v-show="errors.has('Name')" class="help-block">{{
									errors.first('Name')
								}}</span>
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Description" label-for="description" description>
								<b-form-textarea name="Description" type="text" v-model="form.description" maxlength="200"
									:rows="3" placeholder="Description" 
									v-validate="{
										required:true,
										regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
									}"/>
								<span v-show="errors.has('Description')" class="help-block">{{
									errors.first('Description')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="my-3">
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Unit of Measure (UOM):" label-for="uom">
								<b-form-input name="uom" type="text" v-model="form.uom" maxlength="25" readonly />
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group label="Status">
								<b-form-radio-group v-model="form.isActive" :options="statusOptions" name="isActive" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import api from '@/api/assetTypeApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-asset-type',
	components: {
		Loading,
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				origin: '',
				originId: '',
				brand: '',
				type: null,
				description: '',
				uom: '',
				isActive: 'true',
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},

			selCompany: config.companyDefaultValue,
			loggedUser: this.$store.getters.loggedUser,

			statusOptions: config.statusOptions,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.updateAssetTypeName();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
	},
	mounted() { },
	methods: {
		updateAssetTypeName() {
			let brand = '';
			if (this.selCompany.brand) {
				brand = this.selCompany.brand;
			}
			let type = '';
			if (this.form.type) {
				type = this.form.type;
			}
			this.form.name = brand + ' ' + type;
		},
		updateCompanySelection() {
			this.form.origin = this.selCompany.name;
			this.form.originId = this.selCompany.id;
			this.form.brand = this.selCompany.brand;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// assign the selected company to the user
			this.updateCompanySelection();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},

		processFormFields() {
			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
		},
		getParam() {
			this.processFormFields();

			return {
				currUserId: this.loggedUser.id,
				assetType: this.form,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await api.saveAssetType(this.getParam());

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(
						`Asset Type "${this.name}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditAssetType', {
						assetType: data.assetType,
					});
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating asset type "${this.name}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating asset type "${this.name}". Please try again.`
				);
			}
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			let assetTypeId = this.$store.getters.currAssetType.id;
			let param = {
				currUserId: this.loggedUser.id,
				assetTypeId: assetTypeId,
			};
			const { data } = await api.getAssetType(param);
			let assetType = data.assetType;

			let assetTypeJSON = JSON.stringify(assetType);
			this.form = JSON.parse(assetTypeJSON);

			// update other fields
			this.form.uom = assetType.uom ? assetType.uom : null;

			// init company
			this.selCompany = {
				id: assetType.originId,
				name: assetType.origin,
				brand: assetType.brand,
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.lbl-passed {
	font-weight: bold !important;
	color: #28a745 !important;
}

.lbl-failed {
	font-weight: bold !important;
	color: #dc3545 !important;
}
</style>