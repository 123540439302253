<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-form-group
						label="Name:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.name }}
					</b-form-group>

					<b-form-group
						label="Description:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						<span style="width: 500px; word-wrap: break-word !important">{{
							showValue(row.item.description)
						}}</span>
					</b-form-group>

					<b-form-group
						label="Category:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						<span style="width: 500px; word-wrap: break-word !important">{{
							showValue(row.item.type)
						}}</span>
					</b-form-group>

					<b-form-group
						label="Asset Type Owner:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.origin }}
					</b-form-group>

					<b-form-group
						label="Unit of Measure (UOM):"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.uom }}
					</b-form-group>

					<b-form-group
						label="Date Created:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group
						label="Date Updated:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
	name: 'asset-type-details-view',
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	methods: {
		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
};
</script>

<style>
</style>
