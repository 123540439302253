var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"edit-asset-type","size":"lg","title":"Edit Asset Type","ok-title":"Update","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true},on:{"ok":_vm.handleOk,"show":_vm.onReset}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('div',[_c('b-row',{staticClass:"my-3"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Origin","label-for":"origin","description":"Only the companies with Asset Owner or Hybrid type are listed here."}},[_c('b-form-input',{attrs:{"name":"Origin","type":"text","maxlength":"25","readonly":""},model:{value:(_vm.form.origin),callback:function ($$v) {_vm.$set(_vm.form, "origin", $$v)},expression:"form.origin"}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"Category"}},[_c('b-form-input',{attrs:{"name":"Category","type":"text","maxlength":"25","readonly":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1)],1),_c('b-row',{staticClass:"my-3"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","description":'This is a predetermined value based on the selected type and origin'}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
									required: true,
									regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
								}),expression:"{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tregex: /^([A-Za-z0-9\\s\\-:()\\\\/,]{1,50})$/,\n\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Name","type":"text","readonly":true,"maxlength":"50"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Name')),expression:"errors.has('Name')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Name')))])],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description","description":""}},[_c('b-form-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({
									required:true,
									regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
								}),expression:"{\n\t\t\t\t\t\t\t\t\trequired:true,\n\t\t\t\t\t\t\t\t\tregex: /^([0-9:;,.'\\\\\\/()\\-_a-zA-Z\\n ])*$/\n\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Description","type":"text","maxlength":"200","rows":3,"placeholder":"Description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Description')),expression:"errors.has('Description')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Description')))])],1)],1)],1),_c('b-row',{staticClass:"my-3"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Unit of Measure (UOM):","label-for":"uom"}},[_c('b-form-input',{attrs:{"name":"uom","type":"text","maxlength":"25","readonly":""},model:{value:(_vm.form.uom),callback:function ($$v) {_vm.$set(_vm.form, "uom", $$v)},expression:"form.uom"}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('b-form-radio-group',{attrs:{"options":_vm.statusOptions,"name":"isActive"},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}})],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }